import {ajax} from "@/utils";
import {etdfile} from "assets/const";
// 图片预览
export function viewImage (params){
  return ajax({
    url:etdfile+'viewImage',
    responseType: "blob",
    params
  }).then((res)=>{
    return Promise.resolve(res)
  }).catch(err=>{
    return Promise.resolve(false)
  })
}
