
import {ajax} from "@/utils";
import {stutype} from "@/assets/const";
import qs from "qs";

//获取学生类型列表
export function getStutypeListFn (data={}) {
    const {pageno=0, pagesize=0,isdegree,tpcode} = data
    return ajax({
        method: 'post',
        url: stutype + 'list',
        data: {
            pageno,
            pagesize,
            isdegree,
            tpcode
        }
    }).then((res) => {
        return Promise.resolve(res)
    })
}