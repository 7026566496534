import {ajax} from "@/utils";
import {docobject} from "assets/const";


// 版权保护浏览全文
export function drmView (params){
  return ajax({
    url:docobject+'drmView',
    params
  }).then((res)=>{
    return Promise.resolve(res)
  }).catch(err=>{
    return Promise.resolve(false)
  })
}
