//培养单位列表
import {ajax} from "@/utils";
import {trainingunit} from "@/assets/const";

export function getTrainingList(data={}){
    let {pageno=0,pagesize=0,tenantcode,unitcode,unitname} = data;
    return ajax({
        method:'post',
        url:trainingunit+'list',
        data:{
            "pageno": pageno,
            "pagesize": pagesize,
            "tenantcode": tenantcode,
            "unitcode": unitcode,
            "unitname": unitname
        }
    }).then((res)=>{
        return Promise.resolve(res)
    }).catch(()=>{
        return Promise.resolve(false)
    })
}
