import router from './router'
import store from './store'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css'
import {clearLoginInfo, getLocationItem, publixLoginGuid, removeLocationItem, setLocationItem} from "@/utils";

NProgress.configure({ showSpinner: false }) // NProgress Configuration
// const freeRouter = ['/','/login']
let blackList=['/commit']
let isDetailAdded = false;
router.beforeEach(async (to, from, next)=>{
  // start progress bar
  NProgress.start();

  if(!store.state.language) {
    let language = localStorage.getItem('language');
    if(!(language && !['null','undefined'].includes(language))){
      language='zh_CN'
    }
    localStorage.setItem('language',language);
    store.commit('SET_LANGUAGE', language);
  }

  // if(freeRouter.indexOf(to.path)>-1) {//如果是login页面不需要用户信息就直接可以访问
  //   next();
  //   NProgress.done();
  //   return;
  // }


  if(!store.state.tenantInfo) {
    let tenantInfo = JSON.parse(localStorage.getItem('tenantInfo'));
    if(tenantInfo){
      store.commit('SET_TENANTINFO', tenantInfo);
    }
  }

  if(!store.state.agreeState) {
    let agreeState = JSON.parse(localStorage.getItem('agreeState'));
    store.commit('SET_AGREESTATE',agreeState || false)
  }

  //如果统一认证回来的
  if(to.query.learnid && to.query.vcode){
    const {learnid,vcode,name} = to.query;
    const casRes = await store.dispatch('setCasLoginUserInfo',{
      ...store.state.systemInfo,vcode,learnid,name
    })
    if(casRes?.code===200){
      const casInfo = casRes?.data
      if(casInfo?.logincode==1){
        if(!store.state.currentPaperInfo) {
          await store.dispatch('setCurrentPaperInfo',{
            ...store.state.userInfo
          })
        }
        const currentPaperInfo = store.state.currentPaperInfo;
        const loginUrl = getLocationItem('loginUrl')
        removeLocationItem('loginUrl')
        if(loginUrl && !['commit'].includes(loginUrl)){//如果缓存有地址且不是论文提交页，就跳转到对应地址，论文提交页单独处理，走之前逻辑
          window.location.href=loginUrl
          return
        }
        if((!currentPaperInfo?.lwstatus || currentPaperInfo?.lwstatus === 'notsubmit')) {//如果没提交过论文,到个人信息页
          next('/commit/info/myInfo')
        }else{
          next('/commit/info/paperReview')
        }
      }else if(casInfo?.logincode==2){
        next('/information')
      }
    }
  }


  if(!store.state.userInfo) {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let tenantInfo = JSON.parse(localStorage.getItem('tenantInfo'));
    let token = localStorage.getItem('token');
    if(userInfo) {//如果有用户信息
      store.commit('SET_USERINFO', userInfo);
      store.commit('SET_TOKEN', token);
      if(userInfo.managertype==='tenant'){//如果是单位机构
        store.commit('SET_TENANTINFO', tenantInfo);
      }
    }
  }

  const systemInfo = store.state.systemInfo
  if (to.path === '/detail' && !isDetailAdded && systemInfo?.metadataLoginFilter==='1') {//查看元数据需要登录
    blackList.push('/detail');
    isDetailAdded = true;
  }

  const isBlacklisted = blackList.some(item => to.path.startsWith(item));

  if(store.state.userInfo){
    if(to.path==='/login'){
      next('/')
    }else{
      //这边是登录后的
      if(to.path.indexOf('/commit')>-1){//如果当前是提交页面
        if(!store.state.currentPaperInfo) {
          await store.dispatch('setCurrentPaperInfo',{
            ...store.state.userInfo
          })
        }
        const currentPaperInfo = store.state.currentPaperInfo;
        //回执单权限控制
        if(['ReturnReceipt'].includes(to.name)){
          if(!['waitinclude','included'].includes(currentPaperInfo?.lwstatus)){
            next('/wrong')
            return
          }
        }
      }
      next();
    }
  }else{

    // if(!isBlacklisted){//白名单包括login，放行
    //   console.log('白名单')
    //   console.log(to)
    //   console.log(from)
    //   if(to.path==='/login' && to.path !== from.path ){
    //     console.log(1111)
    //     next(`/login?redirect=${encodeURIComponent(from.path)}`)
    //     return
    //   }else{
    //     next()
    //   }
    //
    // }else{//如果直接访问的地址属于黑名单，去登录
    //   console.log('黑名单')
    //   next(`/login?redirect=${encodeURIComponent(to.path)}`)
    // }

    if(!isBlacklisted){//白名单包括login，放行
      console.log('白名单')
      if(to.path==='/login'){//登录之前要存个缓存地址
        if(!to?.query?.redirect){//代表是白名单，不是重定向过来的
          setLocationItem('loginUrl',window.location.href)//存入要跳转的地址，登录后直接跳转
        }
        //拦截去登录
        if(['2'].includes(systemInfo?.loginType)){//如果只配置了统一认证
          window.location.href=systemInfo?.casloginUrl;
          return
        }
      }
      next()
    }else{//如果直接访问的地址属于黑名单，去登录
      if(to.path.startsWith('/commit')){
        setLocationItem('loginUrl','commit')//存入要跳转的地址，登录后直接跳转
      }else{
        setLocationItem('loginUrl',window.location.href)//存入要跳转的地址，登录后直接跳转
      }
      //拦截去登录
      if(['2'].includes(systemInfo?.loginType)){//如果只配置了统一认证
        window.location.href=systemInfo?.casloginUrl;
      }else{
        next(`/login?redirect=${encodeURIComponent(to.path)}`)
      }
    }

  }

})


router.afterEach(()=>{
	NProgress.done();
})

router.beforeResolve(function (to, form, next) {
  document.title = store.state.systemInfo?.systemName || '学位论文管理系统';
  next();
});
