import {ajax} from "@/utils/axios";
import {papersearch} from "@/assets/const";

export function simpSearch(data) {
    return ajax({
        method:'post',
        url:papersearch+'simpSearch',
        data
    }).then(res=>{
        return Promise.resolve(res.data)
    }).catch(error=>{
        return Promise.reject(error)
    })
}

export function getPaperList(data={}){
    const {learnid,tenantcode} = data;
    return ajax({
        method:'post',
        url:papersearch+'learnidPapers',
        params:{
            learnid,
            tenantcode
        }
    }).then((res)=>{
        return Promise.resolve(res.rows || [])
    })
}