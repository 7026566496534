import * as types from "./mutation-types";
import {SET_CURRENT_PAPER_INFO, SET_LANGUAGE} from "./mutation-types";
import {getPaperList, getTenantInfo} from "@/common/interface";
import {systemInfo} from "@/store/getters";
import {casloginFn} from "common/interface/account";
import store from "@/store/index";
export const setUserInfo = function ({ commit }, userInfo) {
	commit(types.SET_USERINFO, userInfo);
};
export const setToken = function ({ commit }, token) {
	commit(types.SET_TOKEN, token);
};
export const setTenantInfo = function ({ commit }, tenantInfo) {
	commit(types.SET_TENANTINFO, tenantInfo);
};
export const setAdvanceSearchGroup = function ({ commit }, advanceSearchGroup) {
	commit(types.SET_ADVANCESEARCHGROUP, advanceSearchGroup);
};
export const setAgreeState = function ({ commit }, agreeState) {
	commit(types.SET_AGREESTATE, agreeState);
};
export const setLanguage = function ({ commit }, language) {
	commit(types.SET_LANGUAGE, language);
};
export const setSystemInfo = function ({ commit }, systemInfo) {
  commit(types.SET_SYSTEM_INFO, systemInfo);
};
// export const setCurrentPaperInfo = function ({ commit }, currentPaperInfo) {
// 	commit(types.SET_CURRENT_PAPER_INFO, currentPaperInfo);
// };
// export const set= async function ({ commit , state }, payload) {
// 	console.log(payload)
// 	if(state.rows === null) {
// 		const rows = await getPaperList(payload)
// 		commit('设置rows', rows)
// 	}
// 	return state.rows
// }
export const setCurrentPaperInfo= async function ({ commit , state }, payload) {
	// if(state.currentPaperInfo === null) {//null为初始化没有发请求的时候，
		const {learnid,tenantcode}= payload
		const rows = await getPaperList({learnid,tenantcode})
		const defaultItem=rows?.[0] || {}
		commit(types.SET_CURRENT_PAPER_INFO, defaultItem)
	// }
	// return state.currentPaperInfo
}

export const setCasLoginUserInfo= async function ({ commit , state }, payload) {
  // if(state.currentPaperInfo === null) {//null为初始化没有发请求的时候，
  const {learnid,name,currentTenant,vcode}= payload
  const res = await casloginFn({learnid,name,tenantcode:currentTenant?.tenantcode,vcode})
  if(res.code===200){
    const casInfo = res.data || {};
    if(casInfo?.logincode==1){
      const accountinfo = casInfo?.accountinfo;
      const token = casInfo?.token;
      localStorage.setItem('userInfo', JSON.stringify(accountinfo));
      store.commit('SET_USERINFO', accountinfo);
      localStorage.setItem('token', token);
      store.commit('SET_TOKEN', token);
      let tenantRes = await getTenantInfo(accountinfo.tenantcode)
      let tenantInfo = tenantRes.data;
      localStorage.setItem('tenantInfo', JSON.stringify(tenantInfo));
      store.commit('SET_TENANTINFO', tenantInfo);
    }
    commit(types.SET_CAS_INFO, casInfo)
  }
  return Promise.resolve(res)
}
