import * as types from "./mutation-types";
import store from "@/store";
import {SET_CAS_INFO, SET_CURRENT_PAPER_INFO, SET_SYSTEM_INFO} from "./mutation-types";
const mutations = {
	[types.SET_USERINFO](state, userInfo) {
		state.userInfo = userInfo;
	},
	[types.SET_TOKEN](state, token) {
		state.token = token;
	},
	[types.SET_TENANTINFO](state, tenantInfo) {
		state.tenantInfo = tenantInfo;
	},
	[types.SET_ADVANCESEARCHGROUP](state, advanceSearchGroup) {
		state.advanceSearchGroup = advanceSearchGroup;
		// state.advanceSearchGroup = Object.assign(state.advanceSearchGroup, advanceSearchGroup)
	},
	[types.SET_AGREESTATE](state,agreeState){
		state.agreeState=agreeState
	},
	[types.SET_CURRENT_PAPER_INFO](state,currentPaperInfo){
		state.currentPaperInfo=currentPaperInfo
	},
	[types.SET_LANGUAGE](state,language){
		state.language=language
	},
  [types.SET_SYSTEM_INFO](state,systemInfo){
    state.systemInfo=systemInfo
  },
  [types.SET_CAS_INFO](state,casInfo){
    state.casInfo=casInfo
  },
  [types.CLEARALL](state) {
    let init = {
      userInfo: "", // 用户
      // language: "", // 语种
      token: "",
      tenantInfo: "",
      // systemInfo: "",//系统设置
      advanceSearchGroup: "", // 高级检索
      agreeState:false,//是否同意协议
      currentPaperInfo:null,//当前的论文信息
      casInfo:null
    }
    for(let key in init) {
      state[key] = init[key]
    }
  }
}
export default mutations;
