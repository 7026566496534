import {language} from "@/store/getters";

const state = {
	userInfo: "", // 用户
	language: "", // 语种
	token: "",
	tenantInfo: "",
	systemInfo: "",//系统设置
	advanceSearchGroup: "", // 高级检索
	agreeState:false,//是否同意协议
	currentPaperInfo:null,//当前的论文信息
  casInfo:null
	// authorizeForm:''//授权书form
}
export default state;
