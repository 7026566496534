
import {ajax} from "@/utils";
import {defvalue} from "@/assets/const";

// 返回缺省值列表
export function defvalueList (data={}){
  const {refid,fadefvalueid} = data
    return ajax({
        method:'post',
        url:defvalue+'list',
        params:{
            refid,
          fadefvalueid
        }
    }).then((res)=>{
        return Promise.resolve(res)
    })
}
