import {ajax} from "@/utils";
import {middle} from "assets/const";
// 论文中间库信息查询
export function getMiddleAccountInfo (params){
  return ajax({
    url:middle+'accountinfo',
    params
  }).then((res)=>{
    return Promise.resolve(res)
  }).catch(err=>{
    return Promise.resolve(false)
  })
}
