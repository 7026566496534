//机构用户配置关联公用方法

/**
 * 获取机构的提交类型配置，和学生类型进行关联，为了用户修改学生类型的时候关联
 * @param tenantInfo
 * @returns {*[]}
 */
export function getTenantStutypeListFn(tenantInfo){
  let toplevelList = []
  if (!tenantInfo?.toplevel) {//如果没有设置toplevel,则默认学位论文和博士后报告都有
    toplevelList=['paper','report']
  } else {
    toplevelList = tenantInfo?.toplevel.indexOf(',') ? tenantInfo?.toplevel.split(',').filter(item => item) : [tenantInfo?.toplevel]
  }
  return toplevelList
}


export function getSystemStutypeListFn(tenantInfo){
  let toplevelList = []
  if (!tenantInfo?.toplevel?.length) {//如果没有设置toplevel,则默认学位论文和博士后报告都有
    toplevelList=['paper','report']
  } else {
    toplevelList = tenantInfo?.toplevel
  }
  return toplevelList
}
