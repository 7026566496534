import {ajax, clearLoginInfo} from "@/utils";
import {account} from "@/assets/const";

export function loginFn(data){
	return ajax({
		method:'post',
		url:account+'login',
		data:data
	}).then((res)=>{
		return Promise.resolve(res)
	}).catch(()=>{
		return Promise.resolve(false)
	})
}

export function logoutFn(){
	return ajax({
		method:'post',
		url:account+'logout'
	}).then((res)=>{
		return Promise.resolve(res)
	})
}


export function casloginFn(params={}){
  return ajax({
    method:'post',
    url:account+'caslogin',
    params
  }).then((res)=>{
    return Promise.resolve(res)
  })
}

export function allowSubmit(){
  return ajax({
    method:'post',
    url:account+'allowSubmit'
  }).then((res)=>{
    return Promise.resolve(res)
  }).catch((err)=>{
    return Promise.resolve(false)
  })
}
