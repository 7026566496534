
export function blobDownload(response) {
  let blob = new Blob([response.data]);　　　 // 创建Blob对象，设置文件类型
  let objectUrl = URL.createObjectURL(blob);　　　　// 创建URL
  let link = document.createElement('a');
  link.href = objectUrl;
  debugger
  if(response.headers["content-disposition"]){
    const disposition = response.headers["content-disposition"];
    const fileName = fileNameFromHeader(disposition);
    link.download = `${fileName}`// 自定义文件名
  }
  link.click() // 下载文件
  URL.revokeObjectURL(objectUrl);
}

export function fileNameFromHeader(disposition) {
  let result = null;
  if (disposition && /filename=.*/gi.test(disposition)) {
    result = disposition.match(/filename=.*/gi);
    return decodeURI(result[0].split("=")[1]);
  }
  return null;
}

//原生ajax下载文件
export function xhrDownload(disposition,url){
  const fileName = fileNameFromHeader(disposition);
  const link = document.createElement("a");
  link.style.display = "none";
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

