import Vue from 'vue'
import VueI18n from 'vue-i18n'
// import Cookies from 'js-cookie'
// element ui 国际化
import Element from 'element-ui'
import elementEnLocale from 'element-ui/lib/locale/lang/en' // element-ui lang导入Element的语言包 英文
import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN'// element-ui lang g导入Element的语言包 中文
import enLocale from './language/en_US.json' // 导入项目中用到的英文语言包
import zhLocale from './language/zh_CN.json'// 导入项目中用到的中文语言包
Vue.use(VueI18n)
const messages = {
    en_US: {
        ...enLocale,
        ...elementEnLocale
    },
    zh_CN: {
        ...zhLocale,
        ...elementZhLocale,
    },

}

const i18n = new VueI18n({
    locale: localStorage.getItem('language') || 'zh_CN', // 设置语种
    messages, // 设置全局当地语言包,
    fallbackLocale: 'zh_CN',
    // numberFormats:{ //设置 数字本地化
    //     'en_US': {
    //         currency: { //添加 $
    //             style: 'currency', currency: 'USD'
    //         }
    //     },
    //     'zh_CN': {
    //         currency: { //添加 ￥
    //             style: 'currency', currency: 'JPY', currencyDisplay: 'symbol'
    //         }
    //     }
    // },
    // dateTimeFormats:{//设置 日期时间本地化
    //     'en_US': {
    //         short: {
    //             year: 'numeric', month: 'short', day: 'numeric'
    //         },
    //         long: {
    //             year: 'numeric', month: 'short', day: 'numeric',
    //             weekday: 'short', hour: 'numeric', minute: 'numeric'
    //         }
    //     },
    //     'zh_CN': {
    //         short: {
    //             year: 'numeric', month: 'short', day: 'numeric'
    //         },
    //         long: {
    //             year: 'numeric', month: 'short', day: 'numeric',
    //             weekday: 'short', hour: 'numeric', minute: 'numeric'
    //         }
    //     }
    // }
})

// vue配置
Vue.use(Element, {
    i18n: (key, value) => i18n.t(key, value)
})

export default i18n
