import {ajax} from "@/utils";
import {secret} from "@/assets/const";

//获取保密级别列表
export function getSecretList(data = {}) {
    return ajax({
        method: 'post',
        url: secret + 'list'
    }).then((res) => {
        return Promise.resolve(res)
    })
}

// 根据id获取保密级别信息
export function getSecretInfo(secretid) {
    return ajax({
        method: 'post',
        url: secret + 'info',
        params: {
            secretid: secretid
        }
    }).then(res => {
        return Promise.resolve(res)
    })
}

// 添加
export function addSecret(data={}) {
    const {opendaynum, secredesc, secrename, secrenameEng}=data
    return ajax({
        method: 'post',
        url: secret + 'add',
        data: {
            "opendaynum": opendaynum,
            "secredesc": secredesc,
            "secrename": secrename,
            "secrenameEng": secrenameEng
        }
    }).then(res=>{
        return Promise.resolve(res)
    })
}

// 删除
export function modifySecret(data={}) {
    const {opendaynum, secredesc, secrename, secrenameEng, secretid}=data
    return ajax({
        method: 'post',
        url: secret + 'modify',
        data: {
            "opendaynum": opendaynum,
            "secredesc": secredesc,
            "secrename": secrename,
            "secrenameEng": secrenameEng,
            "secretid": secretid,
        }
    }).then(res=>{
        return Promise.resolve(res)
    })
}
