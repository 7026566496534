import axios from 'axios';
import store from '../store'
import { Message } from "element-ui";

export function ajax(options){
	return new Promise((resolve,reject) => {
		axios({
			method: options.method || "get",
			headers: options.headers,
			baseURL:"/md/",
			url: options.url,
			timeout: 200000, // 设置请求超时时间
			data: options.data || "",
			params: options.params || "",
			responseType: options.responseType || "",
		}).then((response)=>{
			if(options.responseType && ['blob'].includes(options.responseType)){//如果是二进制流
				resolve(response);
			}else{
				if (response.status === 200 || response.status === 204) {
					const res = response.data;
					if (res.code === 200) {
						resolve(res);
					}else {
						reject(res)
						if(!options.hideMessage){
							Message.error(store.state.language === 'zh_CN' ? res.cnMessage : ( res.enMessage||res.cnMessage))
						}
					}
				}else {
					reject(response.data);
				}
			}
		}).catch((error) => {
			console.log(error);
			reject(error);
		});
	})
}
