import Vue from 'vue'
import dayjs from "dayjs";

//全局使用filter


function formatDate(date) {
  // console.log('date')
  // console.log(date)
  if (!date) {
    return '-';
  }
  return dayjs(date).format('YYYY-MM-DD'); // 使用 dayjs 格式化时间
}

const Filter = {
  formatDate
}

Object.keys(Filter).forEach(key => {
  Vue.filter(key, Filter[key])
})
