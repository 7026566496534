import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Layout',
    component: () => import( '../views/layout/index'),
    redirect: '/home',
    children: [
      { // 首页
        path: 'home',
        name: 'Home',
        component: () => import('../views/SearchModule/home/index')
      },
      { // 注册
        path: 'register',
        name: 'Register',
        component: () => import('../views/SearchModule/register/index')
      },
      { // 完善信息
        path: 'information',
        name: 'Information',
        component: () => import('../views/SearchModule/information/index')
      },
      {//通知公告
        path: 'notice',
        name: 'Notice',
        component: () => import('../views/SearchModule/notice/index')
      },
      {//通知公告详情
        path: 'notice/:newid',
        name: 'NoticeDetail',
        component: () => import('../views/SearchModule/notice/detail')
      },
      { // 检索列表
        path: "searchPage",
        name: "Search",
        component: () => import("../views/SearchModule/search"),
      },
      { // 分类浏览
        path: "classificationSearch",
        name: "Classify",
        component: () => import("../views/SearchModule/classify"),
      },
      { // 分类浏览
        path: "clickRanking",
        name: "ClickRanking",
        component: () => import("../views/SearchModule/clickRanking"),
      },
      // {//直接去登录页，没必要出现这个
      //   path: 'logintip',
      //   name: "Logintip",
      //   component: () => import('../components/LoginTip')
      // },
      {//详情
        path: "detail",
        name: "Detail",
        component: () => import("../views/SearchModule/detail"),
      },
      {
        path: 'faq',
        name: 'FAQ',
        component: () => import('../views/SearchModule/faq/index'),
      },
      {
        path: 'commit',
        name: 'CommitModule',
        component: () => import('../views/CommitModule/index'),
        redirect: '/commit/info',
        children: [
          { // 填写信息相关
            path: 'info',
            name: 'Info',
            component: () => import('../views/CommitModule/info'),
            redirect: '/commit/info/myInfo',
            children: [
              { // 我的信息
                path: 'myInfo',
                name: 'MyInfo',
                component: () => import('../views/CommitModule/info/myInfo')
              },
              { // 论文提交
                path: 'paperSubmit',
                name: 'PaperSubmit',
                component: () => import('views/CommitModule/info/paperSubmission')
              },
              { // 查看论文
                path: 'paperReview',
                name: 'PaperReview',
                component: () => import('views/CommitModule/info/paperReview')
              },
              { // 申请重新提交
                path: 'resubmit',
                name: 'Resubmit',
                component: () => import('../views/CommitModule/info/resubmit')
              }
            ]
          },
          { // 回执单
            path: 'returnReceipt',
            name: 'ReturnReceipt',
            component: () => import('../views/CommitModule/returnReceipt'),
          },
          {
            path: 'application',
            name: 'Application',
            component: () => import('../views/CommitModule/application')
          }
        ]
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/login')
  },
  {//加载页
    path: '/loading',
    name: 'Loading',
    component: () => import('../views/loading')
  },
  {//错误页面wrong
    path: '/wrong',
    name: 'Wrong',
    component: () => import('../views/wrong')
  },
  {//403
    path: '/403',
    name: '403',
    component: () => import('../views/403')
  },
  {//404
    path: '/404',
    name: '404',
    component: () => import('../views/404')
  },
  // 404 page must be placed at the end !!!
  {path: '*', redirect: '/404', hidden: true}
]

/*解决vue-router在3.0版本以上重复点菜单报错的问题*/
// const originalPush = VueRouter.prototype.push;
// VueRouter.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch((err) => err);
// };
// 保存原始的push和replace方法，以便后续修改
let originPush = VueRouter.prototype.push
let originReplace = VueRouter.prototype.replace

// 重写Vue Router的push方法，以捕获路由跳转中的错误
VueRouter.prototype.push = function push(location){
  return originPush.call(this, location).catch(err => err)
}

// 重写Vue Router的replace方法，以捕获路由跳转中的错误
VueRouter.prototype.replace = function replace(location){
  return originReplace.call(this, location).catch(err => err)
}

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router

