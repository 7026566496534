// export const changeThemeColor = (rootKey,themeColor) => {
//   document.documentElement.style.setProperty(rootKey, themeColor); // 将新的主题色应用于 :root
// }
// 动态修改自动定义颜色
export const setCssVar = (prop, color, dom = document.documentElement) => {
  dom.style.setProperty(prop, color)
  // setOpacityCssVar('--color-primary-opacity-6', '#1d701a',0.6)      // 替换透明度颜色
  setOpacityCssVar('--color-primary-opacity-1', color,0.1)      // 替换透明度颜色
  setOpacityCssVar('--color-primary-opacity-03', color,0.03)      // 替换透明度颜色
}

const setOpacityCssVar = (prop, color,opacity, dom = document.documentElement) => {
  const lightColor = convertColor(color,opacity)
  dom.style.setProperty(prop, lightColor)
}

const convertColor = (color,opacity) => {
  // 检查是否为16进制颜色值
  if (/^#([0-9A-F]{3}){1,2}$/i.test(color)) {
    // 将3位16进制颜色值扩展为6位
    color = color.replace(/^#([0-9A-F]{3})$/i, (_, hex) => {
      return '#' + hex.replace(/./g, '$&$&');
    });

    // 将16进制转换为RGBA
    const bigint = parseInt(color.slice(1), 16);
    const red = (bigint >> 16) & 255;
    const green = (bigint >> 8) & 255;
    const blue = bigint & 255;

    // 返回带有透明度的RGBA
    return `rgba(${red}, ${green}, ${blue}, ${opacity})`;
  }

  // 检查是否为RGBA颜色值
  if (/^rgba/i.test(color)) {
    // 将透明度设置为0.6
    return color.replace(/[^,]+(?=\))/, opacity);
  }

  // 检查是否为RGB颜色值
  if (/^rgb/i.test(color)) {
    // 将RGB转换为RGBA并设置透明度为0.6
    return color.replace(')', `, ${opacity})`);
  }

  // 如果无法识别颜色值，则返回原始输入
  return color;
}
