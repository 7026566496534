import {ajax} from "@/utils";
import {setting} from "assets/const";

//获取系统配置
export function getPersonalitySetting(){
  return ajax({
    url: setting + 'personalitySetting'
  }).then((res) => {
    return Promise.resolve(res)
  }).catch(eer=>{
    return Promise.resolve(false)
  })
}
