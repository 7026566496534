export const SET_USERINFO = "SET_USERINFO";
export const SET_TOKEN = "SET_TOKEN";
export const SET_TENANTINFO = "SET_TENANTINFO";
export const SET_ADVANCESEARCHGROUP = "SET_ADVANCESEARCHGROUP";
export const SET_AGREESTATE = "SET_AGREESTATE";
export const SET_CURRENT_PAPER_INFO = "SET_CURRENT_PAPER_INFO";
export const SET_LANGUAGE = "SET_LANGUAGE";
export const SET_SYSTEM_INFO = "SET_SYSTEM_INFO";
export const SET_CAS_INFO = "SET_CAS_INFO";
export const CLEARALL = "CLEARALL";
