import store from '../../store'
import {getStutypeListFn} from "common/interface";
import {getSystemStutypeListFn, getTenantStutypeListFn} from "@/utils";

const stutypeStore = {
  namespaced: true,
  state: () => ({
    allStutypeList: [],//所有的学生类型列表
    tenantStutypeList: []//机构负责的的学生类型列表
  }),
  getters: {
    userBelongPapertype (state) {
      let papertype= '';
      const userInfo = store.state.userInfo
      if((!userInfo.stutypeid)){
        return 'paper'
      }
      const stutypeItem = state.allStutypeList?.find(item=>item.stutypeid===userInfo?.stutypeid)
      papertype=stutypeItem?.tpcode || 'paper'
      // console.log(papertype)
      return papertype
    },
    tenantStutypeList(state){
      const tenantStutypes = getTenantStutypeListFn(store.state.tenantInfo)
      const allStutypeList=state.allStutypeList
      const stutypeFilterList=allStutypeList.filter(item=>tenantStutypes.includes(item.tpcode)) || [];
      return stutypeFilterList
    },
   systemTpcodeList(state){
      const systemStutypes = getSystemStutypeListFn(store.state.systemInfo)
     const allStutypeList=state.allStutypeList
     const stutypeFilterList=allStutypeList.filter(item=>systemStutypes.includes(item.tpcode))
     const tpcodeList=(stutypeFilterList || []).map(item=>item.tpcode)
      return tpcodeList
    }
  },
  mutations: {
    setAllStutypeList(state, allStutypeList){
      state.allStutypeList = allStutypeList
    }
  },
  actions: {
    //更新所有学生类型列表
    async updateAllStutypeList({commit}){
      const stutypeRes = await getStutypeListFn()
      const rows=stutypeRes.rows || []
      commit("setAllStutypeList", rows);
    },
  }
}
export default stutypeStore
