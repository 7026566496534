import {ajax} from "@/utils";
import {departments} from "@/assets/const";

//获取院系专业列表
export function getDepartmentsList(data={}) {
	let {islimit,pageno=0,pagesize=0,paranentid,searchname,tenantcode} = data;
	return ajax({
		method:'post',
		url:departments+'list',
		data:{
			"islimit": islimit,//是否过期 no：未过期 yes过期
			"pageno": pageno,
			"pagesize": pagesize,
			"paranentid": paranentid,//0:表示院系， -1:显示所有专业
			"searchname": searchname,
			"tenantcode": tenantcode
		}
	}).then((res)=>{
		return Promise.resolve(res)
	})
}

//院系删除
export function delDepartments(id){
	return ajax({
		method:'post',
		url:departments+'del',
		params:{
			id
		}
	}).then((res)=>{
		return Promise.resolve(res)
	})
}

//院系专业添加
export function addDepartments(data={}){
	let {cname,code,ename,tenantcode,paranentid} = data;
	return ajax({
		method:'post',
		url:departments+'add',
		data:{
			"cname": cname,
			"code": code,
			"ename": ename,
			"paranentid": paranentid,//	0:表示院系，其他表示专业对应的院系ID
			"tenantcode": tenantcode
		}
	}).then((res)=>{
		return Promise.resolve(res)
	})
}

//院系专业修改
export function editDepartments(data={}) {
	let {cname,code,ename,paranentid,id,islimit,tenantcode} = data;
	return ajax({
		method:'post',
		url:departments+'update',
		data:{
			"cname": cname,
			"code": code,
			"ename": ename,
			"id": id,
			"islimit": islimit,
			"paranentid": paranentid,
			"tenantcode": tenantcode
		}
	}).then((res)=>{
		return Promise.resolve(res)
	})
}
