import {ajax} from "@/utils";
import {tenant} from "assets/const";

export const getTenantInfo = (tenantcode) => {
  return ajax({
    method: 'post',
    url: tenant + 'selecttenant',
    params: {
      tenantcode
    }
  }).then((res) => {
    return Promise.resolve(res);
  }).catch(() => {
    return Promise.resolve(false);
  })
}
