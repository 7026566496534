import {ajax} from "@/utils/axios";
import {sortlist} from "@/assets/const";

// 根据父分类获取分类列表
export function getSortListByParentid(sortparentid, taxid){
    return ajax({
        method:'post',
        url:sortlist+'getSortListByParentid',
        params: {
            sortparentid: sortparentid,
            taxid: taxid
        }
    }).then(res=>{
        return Promise.resolve(res.data)
    })
}
// 获取分类列表
export function getSortList(level, taxid){
    return ajax({
        method:'post',
        url:sortlist+'list',
        params: {
            level: level,
            taxid: taxid
        }
    }).then(res=>{
        return Promise.resolve(res.data)
    })
}
