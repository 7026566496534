export const getOpentime = (opendaynum) => {
  let opentime;
  const currentDate = new Date();
  if (opendaynum >= 100) {//多少天后开放,默认按年，如果值大于100则按天记算
    opentime = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + opendaynum);
  } else {
    opentime = new Date(currentDate.getFullYear() + opendaynum, currentDate.getMonth(), currentDate.getDate())
  }
  return formatDate(opentime)
}

const formatDate = (date) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return year + '-' + month + '-' + day;
}

