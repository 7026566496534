import {ajax} from "@/utils";
import {template} from "@/assets/const";

// 返回模板对应的字段
export function getFieldList(fieldtype ,tpcode){
    return ajax({
        method:'post',
        url:template+'fieldList',
        params: {
            fieldtype: fieldtype,
            tpcode: tpcode
        }
    }).then(res=>{
        return Promise.resolve(res.rows)
    })
}

// 获取提取字段
export function submitFieldList (tpcode){
    return ajax({
        method:'post',
        url:template+'submitFieldList',
        params:{
            tpcode
        }
    }).then((res)=>{
        return Promise.resolve(res)
    })
}