//清空登录
import store from "@/store";

export function clearLoginInfo() {
  // console.log('clearLoginInfo')
    // 清空缓存，保留语种和馆信息
    let tenantInfo = JSON.parse(localStorage.getItem('tenantInfo'));
    let language=localStorage.getItem('language');
    // console.log('clearLoginInfo language: '+language)
    localStorage.clear();
    sessionStorage.clear();
    store.commit('CLEARALL');//重置store
    // store.commit('SET_USERINFO', '');
    // store.commit('SET_TOKEN', '');
    // store.commit('SET_CURRENT_PAPER_INFO', null);//...还有别的store可能要重置，有没有一次性重置为state里面定义的初始值的方法
    localStorage.setItem('tenantInfo',JSON.stringify(tenantInfo));
    localStorage.setItem('language',language);
}

export function clearLoginAndReload() {
    clearLoginInfo()
    window.location.reload()//如果一个列表一直401的话会死循环，怎么解决
}



