import router from "@/router";

export function publixLoginGuid(data={}){
  const {systemInfo,loginUrl} = data
  if(loginUrl){//论文提交存特殊的缓存用于区分
    setLocationItem('loginUrl',loginUrl)//存入要跳转的地址，登录后直接跳转
  }else{
    setLocationItem('loginUrl',window.location.href)//存入要跳转的地址，登录后直接跳转
  }
  if(['2'].includes(systemInfo?.loginType)){
    window.location.href=systemInfo?.casloginUrl;
    return
  }
  router.push('/login')
}

export function getLocationItem(key){
  const keyContent = window.localStorage.getItem(key)
  return keyContent
}

export function removeLocationItem(key){
  window.localStorage.removeItem(key)
}

export function setLocationItem(key,value){
  window.localStorage.setItem(key,value)
}
// localStorage.setItem('loginUrl', window.location.href);
